
const config = {
  "account": {
    "contact": "garrett@dynamicloyaltysystems.com",
    "id": "dynamic-loyalty",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/dynamic-loyalty/assets/img/profile.png"
  },
  "client": {
    "id": "caesars-southern-indiana",
    "email": "ueldmteam@caesars.com"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/dynamic-loyalty/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/dynamic-loyalty/assets/img/brand/logo.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/dynamic-loyalty/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://dynamicloyalty.ai",
      "name": "Dynamic Loyalty Systems, Inc."
    }
  },
  "theme": {
    "palette": {
      "color-1": "#285DD3",
      "color-2": "#00054B",
      "color-3": "#6D23BF",
      "color-4": "#67CED9",
      "color-5": "#285DD3"
    }
  },
  "etl": {
    "api": {
      "url": "https://us-central1-dls-platform-prod.cloudfunctions.net/etl-api/etl-api",
      "combine": {
        "url": "https://us-central1-dls-platform-prod.cloudfunctions.net/etl-client-portal-caesars-southern-indiana-combine"
      }
    },
    "bigquery": {
      "project": "easandbox1",
      "dataset": "edw_auth"
    }
  }
}

export default config;
